:root {
  --md-source: #0261A5;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #001c37;
  --md-ref-palette-primary20: #003259;
  --md-ref-palette-primary25: #003d6b;
  --md-ref-palette-primary30: #00497e;
  --md-ref-palette-primary35: #005591;
  --md-ref-palette-primary40: #0261a5;
  --md-ref-palette-primary50: #327ac0;
  --md-ref-palette-primary60: #5194db;
  --md-ref-palette-primary70: #6eaff8;
  --md-ref-palette-primary80: #9fcaff;
  --md-ref-palette-primary90: #d2e4ff;
  --md-ref-palette-primary95: #eaf1ff;
  --md-ref-palette-primary98: #f8f9ff;
  --md-ref-palette-primary99: #fdfcff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #221b00;
  --md-ref-palette-secondary20: #393000;
  --md-ref-palette-secondary25: #463b00;
  --md-ref-palette-secondary30: #534600;
  --md-ref-palette-secondary35: #605200;
  --md-ref-palette-secondary40: #6e5e00;
  --md-ref-palette-secondary50: #8b7600;
  --md-ref-palette-secondary60: #a89007;
  --md-ref-palette-secondary70: #c4aa2b;
  --md-ref-palette-secondary80: #e1c645;
  --md-ref-palette-secondary90: #ffe25f;
  --md-ref-palette-secondary95: #fff1be;
  --md-ref-palette-secondary98: #fff9ee;
  --md-ref-palette-secondary99: #fffbff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #001e2b;
  --md-ref-palette-tertiary20: #003547;
  --md-ref-palette-tertiary25: #004156;
  --md-ref-palette-tertiary30: #004d66;
  --md-ref-palette-tertiary35: #005976;
  --md-ref-palette-tertiary40: #006686;
  --md-ref-palette-tertiary50: #0081a8;
  --md-ref-palette-tertiary60: #169cc9;
  --md-ref-palette-tertiary70: #45b7e6;
  --md-ref-palette-tertiary80: #70d2ff;
  --md-ref-palette-tertiary90: #c0e8ff;
  --md-ref-palette-tertiary95: #e1f3ff;
  --md-ref-palette-tertiary98: #f4faff;
  --md-ref-palette-tertiary99: #fafcff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1a1c1e;
  --md-ref-palette-neutral20: #2f3033;
  --md-ref-palette-neutral25: #3a3b3e;
  --md-ref-palette-neutral30: #45474a;
  --md-ref-palette-neutral35: #515255;
  --md-ref-palette-neutral40: #5d5e61;
  --md-ref-palette-neutral50: #76777a;
  --md-ref-palette-neutral60: #909094;
  --md-ref-palette-neutral70: #ababae;
  --md-ref-palette-neutral80: #c6c6ca;
  --md-ref-palette-neutral90: #e2e2e6;
  --md-ref-palette-neutral95: #f1f0f4;
  --md-ref-palette-neutral98: #faf9fc;
  --md-ref-palette-neutral99: #fdfcff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #171c22;
  --md-ref-palette-neutral-variant20: #2c3137;
  --md-ref-palette-neutral-variant25: #373c43;
  --md-ref-palette-neutral-variant30: #43474e;
  --md-ref-palette-neutral-variant35: #4e535a;
  --md-ref-palette-neutral-variant40: #5a5f66;
  --md-ref-palette-neutral-variant50: #73777f;
  --md-ref-palette-neutral-variant60: #8d9199;
  --md-ref-palette-neutral-variant70: #a7abb4;
  --md-ref-palette-neutral-variant80: #c3c6cf;
  --md-ref-palette-neutral-variant90: #dfe2eb;
  --md-ref-palette-neutral-variant95: #edf1fa;
  --md-ref-palette-neutral-variant98: #f8f9ff;
  --md-ref-palette-neutral-variant99: #fdfcff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #0261a5;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #d2e4ff;
  --md-sys-color-on-primary-container-light: #001c37;
  --md-sys-color-secondary-light: #6e5e00;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #ffe25f;
  --md-sys-color-on-secondary-container-light: #221b00;
  --md-sys-color-tertiary-light: #006686;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #c0e8ff;
  --md-sys-color-on-tertiary-container-light: #001e2b;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fdfcff;
  --md-sys-color-on-background-light: #1a1c1e;
  --md-sys-color-surface-light: #fdfcff;
  --md-sys-color-on-surface-light: #1a1c1e;
  --md-sys-color-surface-variant-light: #dfe2eb;
  --md-sys-color-on-surface-variant-light: #43474e;
  --md-sys-color-outline-light: #73777f;
  --md-sys-color-inverse-on-surface-light: #f1f0f4;
  --md-sys-color-inverse-surface-light: #2f3033;
  --md-sys-color-inverse-primary-light: #9fcaff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #0261a5;
  --md-sys-color-outline-variant-light: #c3c6cf;
  --md-sys-color-scrim-light: #000000;
  /* dark */
  --md-sys-color-primary-dark: #9fcaff;
  --md-sys-color-on-primary-dark: #003259;
  --md-sys-color-primary-container-dark: #00497e;
  --md-sys-color-on-primary-container-dark: #d2e4ff;
  --md-sys-color-secondary-dark: #e1c645;
  --md-sys-color-on-secondary-dark: #393000;
  --md-sys-color-secondary-container-dark: #534600;
  --md-sys-color-on-secondary-container-dark: #ffe25f;
  --md-sys-color-tertiary-dark: #70d2ff;
  --md-sys-color-on-tertiary-dark: #003547;
  --md-sys-color-tertiary-container-dark: #004d66;
  --md-sys-color-on-tertiary-container-dark: #c0e8ff;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1a1c1e;
  --md-sys-color-on-background-dark: #e2e2e6;
  --md-sys-color-surface-dark: #1a1c1e;
  --md-sys-color-on-surface-dark: #e2e2e6;
  --md-sys-color-surface-variant-dark: #43474e;
  --md-sys-color-on-surface-variant-dark: #c3c6cf;
  --md-sys-color-outline-dark: #8d9199;
  --md-sys-color-inverse-on-surface-dark: #1a1c1e;
  --md-sys-color-inverse-surface-dark: #e2e2e6;
  --md-sys-color-inverse-primary-dark: #0261a5;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #9fcaff;
  --md-sys-color-outline-variant-dark: #43474e;
  --md-sys-color-scrim-dark: #000000;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: aileron;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: aileron;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: aileron;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: aileron;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: aileron;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: aileron;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: aileron;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: aileron;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: aileron;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: aileron;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: aileron;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: aileron;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: aileron;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: aileron;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: aileron;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
