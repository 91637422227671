/* You can add global styles to this file, and also import other style files */
@import url('https://use.typekit.net/bbr7suf.css');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: aileron, sans-serif;
}

// Add to SYBL Chat Icon
//border: 1px solid #444444;
//border-radius: 26px;

.mat-drawer-container {
  background-color: #6a6a77 !important;
}
.nav-bar-color {
  background-color: #001b26;
} // Top Bar
.app-background {
  background-color: #f0f4f8;
} // Background
.sybl-icon-border {
  //border: 2px solid #444444;
  //border-radius: 24px;
} // Circle Around SYBL Icon Around User Text Bar
.off-black-text-color {
  color: #0d0a32;
} // Off Black Text Color
.separation-lines {
  background-color: #beb7b7;
} // Thin Grey Separation Lines
.suggested-question-arrows {
  background-color: #acacac;
} // Suggested Questions Arrows
.blue-link-color {
  color: #0f469a;
} // Blue Link Color
.thumbs-action {
  background-color: #373e44;
} // Thunbs Action
.background-circle-bottom-left-circle {
  background-color: #ffd2c5;
} // Bottom Left Circle
.background-circle-bottom-right-circle {
  background-color: #c0e7ff;
} // Bottom Right Circle
.background-circle-top-right-circle {
  background-color: #ffffff;
} // Top Right Circle
.orange-button {
  background-color: #ce6925 !important;
} // Button Orange
.user-reply-box {
  background-color: #ededed;
} // User Reply Box Grey
.sign-up-text-box {
  background-color: #ededed;
} // Grey TextBox During Sign Up
.white-font{
  color: #ffffff;
}
.hovered-chat-white-text {
  color: white;
} // Grey Text on Light Grey
.grey-text-on-light-grey {
  color: #444444;
} // Grey Text on Light Grey
.grey-on-yearly-monthly-slider {
  background-color: #d9d9d9;
} // Grey On Yearly Monthly Slider
.grey-lines-choose-plan {
  background-color: #9a9a9a;
} // Grey Lines on Choose Plan
.grey-selection-circles {
  background-color: #878787;
} // Grey Yes No Circles ans Same as Billing Box and Xs on Invite Team Member
.grey-settings-lines {
  background-color: #9a9a9a;
} // Settings Grey Lines and Arrows
.gears-trash-grey {
  background-color: #4f4f4f;
} // Gears and Trash Grey
.trash-grey {
  color: #4f4f4f;
} // Gears and Trash Grey
.add-user-text-box-border {
  background-color: #323232;
} // Add user Text Box Outline
.sign-up-text {
  color: #444444;
} // Grey Text On Light Grey Signuo Boxes
.secondary-font {
  font-family: 'Plus Jakarta Sans';
}
.primary-font {
  font-family: 'aileron';
}
.smart-page-holder {
  min-height: 90vh;
  display: flex;
  margin-top: 86px;
}

.data-loading-spinner {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 10000;
}

.chat-separator-line {
  width: 95%;
  margin: auto;
  height: 2px;
  border-radius: 2px;
  background-color: #beb7b7;
}

.standard-submit-button {
  border-radius: 24px;
  background-color: #ce6925 !important;
  color: #ffffff;
  margin: 1.75em auto;
  width: 150px !important;
  font-size: 13pt;
  font-weight: 600;
  border: 0;
  height: 42px;
}

.onboarding-action-button {
  width: 160px !important;
  height: 40px !important;
  font-size: 13pt !important;
  font-weight: 600 !important;
  border-radius: 24onpx !important;
  background-color: #ce6925 !important;
  color: white !important;
}

.onboarding-next-button-holder {
  margin: 1em 0;
  display: flex;
  justify-content: center;
}

.chat-option-separator-line {
  width: 100%;
  margin-right: 1em;
  height: 2px;
  border-radius: 2px;
  background-color: #beb7b7;
}

.circle-background-images {
  background-image: url('/assets/svg/circles-background.svg#svgView(preserveAspectRatio(none))');
  //  background-image: url('/assets/svg/circles-background.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100%;
}

.data-loading-spinner-fixed {
  width: 100%;
  margin-top: 36vh;
  z-index: 10000;
  position: fixed;
}

.mat-drawer-inner-container {
  background-color: #001b26;
}

.sidenav-content {
  margin-top: 4em;
}

.insherpa-submit-standard-button {
}

.mat-drawer-content {
  background-color: #f0f4f8;
}

.mat-drawer {
  position: fixed !important;
}
//                            Mat Form Field Color Error Fix
/////////////////////////////////////////////////////////////////////////////////////
.mat-error {
  color: #f44336 !important;
}

/* Set the underline to red if dirty and invalid */
.mat-form-field.ng-dirty.ng-invalid .mat-form-field-underline {
  background-color: #f44336 !important;
}

/* Set the label to red if dirty and invalid */
.mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
  color: #f44336 !important;
}

.form-holder {
  display: flex;
  text-align: center;
  flex-direction: row;
}

.fill-full {
  width: 100%;
}

.fill-two-thirds-column {
  flex: 3;
  padding-right: 1em;
}

.fill-two-column {
  width: 45%;
}

.fill-three-column {
  width: 30%;
}

.fill-four-column {
  width: 22%;
}

.fill-five-column {
  width: 20%;
}

.full-width {
  width: 98%;
}
.flex-form-multiline {
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.sybl_message_placeholder {
  border-radius: 12px;
  min-height: 28px;
  padding: 0.5em;
  font-weight: 800;
  font-size: 24pt;
  //  animation: color-transition 4s infinite;
}

.sybl_eye_pulse {
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.scroll-nav-links-outer-holder::-webkit-scrollbar {
  background: transparent;
  /* Chrome/Safari/Webkit */
  width: 0px;
}

@media (max-width: 475px) {
  .scroll-nav-links-outer-holder {
    width: 100%;
    overflow-x: scroll;
    margin: 0 auto 2em auto;
    height: 5em;
  }

  .sybl-bot-chat-image {
    width: 45px;
    height: 45px;
  }

  .sybl-user-chat-image {
    width: 26px;
    border-radius: 13px;
    height: 26px;
  }

  .undefined-avatar-chat {
    font-size: 2.5em;
    display: flex;
    font-weight: 600;
    text-align: center;
    width: 45px;
    font-size: 18pt;
    height: 45px;
    border: 2px solid #444444;
    border-radius: 70px;
  }

  .sybl-bot-chat-image-holder {
    width: 45px;
    height: 45px;
  }
}

@media (min-width: 475px) {
  .scroll-nav-links-outer-holder {
    width: 100%;
    overflow-x: scroll;
    margin: 0 auto 4em auto;
    height: 4em;
  }

  .sybl-bot-chat-image {
    width: 45px;
    height: 45px;
  }

  .sybl-user-chat-image {
    width: 40px;
    border-radius: 24px;
    height: 40px;
  }
  .undefined-avatar-chat {
    font-size: 2.5em;
    display: flex;
    font-weight: 600;
    text-align: center;
    width: 40px;
    font-size: 24pt;
    height: 40px;
    border: 2px solid #444444;
    border-radius: 70px;
  }

  .sybl-bot-chat-image-holder {
    width: 45px;
    height: 45px;
  }
}
